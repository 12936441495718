import { default as about_45youMVi4tHUAVMMeta } from "/vercel/path0/pages/about-you.vue?macro=true";
import { default as _91id_93MrFb9P7oNRMeta } from "/vercel/path0/pages/admin/blogs/[id].vue?macro=true";
import { default as blogso4bmmwth6EMeta } from "/vercel/path0/pages/admin/blogs.vue?macro=true";
import { default as dashboardYi2jyZZMHDMeta } from "/vercel/path0/pages/admin/dashboard.vue?macro=true";
import { default as _91id_932hSgsR4JDDMeta } from "/vercel/path0/pages/admin/essays/[id].vue?macro=true";
import { default as _91id_93FscL10BaqWMeta } from "/vercel/path0/pages/admin/help-center/[id].vue?macro=true";
import { default as help_45centermUnX3GU4L6Meta } from "/vercel/path0/pages/admin/help-center.vue?macro=true";
import { default as _91id_93XSAGQTKuM3Meta } from "/vercel/path0/pages/admin/paraphrases/[id].vue?macro=true";
import { default as editpjBFXhILT2Meta } from "/vercel/path0/pages/admin/products/[id]/edit.vue?macro=true";
import { default as pricesEgjoEI5sKFMeta } from "/vercel/path0/pages/admin/products/[id]/prices.vue?macro=true";
import { default as create8LkXyb2OcpMeta } from "/vercel/path0/pages/admin/products/create.vue?macro=true";
import { default as indexr4iBpyk8AWMeta } from "/vercel/path0/pages/admin/products/index.vue?macro=true";
import { default as _91id_93kaSEixcauRMeta } from "/vercel/path0/pages/admin/quizzes/[id].vue?macro=true";
import { default as _91id_93zYe9mZvkwRMeta } from "/vercel/path0/pages/admin/summaries/[id].vue?macro=true";
import { default as _91id_933pwRGkEYc6Meta } from "/vercel/path0/pages/admin/texts/[id].vue?macro=true";
import { default as indexgo1pS4yjOkMeta } from "/vercel/path0/pages/admin/texts/index.vue?macro=true";
import { default as essaysK9lSew7QlZMeta } from "/vercel/path0/pages/admin/users/[id]/essays.vue?macro=true";
import { default as paraphraseszvyOspmOpxMeta } from "/vercel/path0/pages/admin/users/[id]/paraphrases.vue?macro=true";
import { default as quizzesD7FyGfzBYQMeta } from "/vercel/path0/pages/admin/users/[id]/quizzes.vue?macro=true";
import { default as summariesS2VGGix9OLMeta } from "/vercel/path0/pages/admin/users/[id]/summaries.vue?macro=true";
import { default as _91id_939f4NtHSsVaMeta } from "/vercel/path0/pages/admin/users/[id].vue?macro=true";
import { default as analyticsetOKEJ7f5gMeta } from "/vercel/path0/pages/admin/users/analytics.vue?macro=true";
import { default as indexgxZ1ikbUBwMeta } from "/vercel/path0/pages/admin/users/index.vue?macro=true";
import { default as ai_45essay_45writerLWlVCFQIrnMeta } from "/vercel/path0/pages/ai-essay-writer.vue?macro=true";
import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as pricingt2pDtGfDaSMeta } from "/vercel/path0/pages/checkout/pricing.vue?macro=true";
import { default as returnOojyHHiAtSMeta } from "/vercel/path0/pages/checkout/return.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as documentsQsTdxIaiRLMeta } from "/vercel/path0/pages/documents.vue?macro=true";
import { default as _91slug_939fWiEWqMwpMeta } from "/vercel/path0/pages/files/[slug].vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as parafraquizTeZZPJGDGwMeta } from "/vercel/path0/pages/parafraquiz.vue?macro=true";
import { default as paraphrasing_45toolp1sA3nuR2fMeta } from "/vercel/path0/pages/paraphrasing-tool.vue?macro=true";
import { default as _91token_93hTDxOgyFeIMeta } from "/vercel/path0/pages/password-reset/[token].vue?macro=true";
import { default as _91slug_93un7ONJp8l3Meta } from "/vercel/path0/pages/policies/[slug].vue?macro=true";
import { default as pricingIYNdvfoSO6Meta } from "/vercel/path0/pages/pricing.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as profile3g7qqlrA4oMeta } from "/vercel/path0/pages/settings/profile.vue?macro=true";
import { default as _91id_93YxoFsSxbKOMeta } from "/vercel/path0/pages/texts/[id].vue?macro=true";
import { default as to_45do_45list7kjsrqXPBTMeta } from "/vercel/path0/pages/to-do-list.vue?macro=true";
import { default as _91id_93RRyDBnfIIBMeta } from "/vercel/path0/pages/tools/documents/[id].vue?macro=true";
import { default as documentsvKKHHetvDuMeta } from "/vercel/path0/pages/tools/documents.vue?macro=true";
import { default as _91id_93YoAux6dZqPMeta } from "/vercel/path0/pages/tools/essays/[id].vue?macro=true";
import { default as indexp4ty5M4ppvMeta } from "/vercel/path0/pages/tools/essays/index.vue?macro=true";
import { default as _91id_93EDdzHJUTJaMeta } from "/vercel/path0/pages/tools/essays/table-of-contents/[id].vue?macro=true";
import { default as _91id_93wchdGemELqMeta } from "/vercel/path0/pages/tools/paraphrases/[id].vue?macro=true";
import { default as paraphrasesN69RkGnKHeMeta } from "/vercel/path0/pages/tools/paraphrases.vue?macro=true";
import { default as _91questionId_939L2dBO6WsbMeta } from "/vercel/path0/pages/tools/quizzes/[id]/questions/[questionId].vue?macro=true";
import { default as _91id_93GkxXVuhoCWMeta } from "/vercel/path0/pages/tools/quizzes/[id].vue?macro=true";
import { default as playeO9f7nZ3BKMeta } from "/vercel/path0/pages/tools/quizzes/[slug]/practice/play.vue?macro=true";
import { default as results1qah9fzBAOMeta } from "/vercel/path0/pages/tools/quizzes/[slug]/practice/results.vue?macro=true";
import { default as allPUsaa5tpNiMeta } from "/vercel/path0/pages/tools/quizzes/index/all.vue?macro=true";
import { default as indexaBD9p6HNbMMeta } from "/vercel/path0/pages/tools/quizzes/index.vue?macro=true";
import { default as _91id_93xMEhnw07p8Meta } from "/vercel/path0/pages/tools/quizzes/question-types/[id].vue?macro=true";
import { default as _91id_93xqaQkivPd2Meta } from "/vercel/path0/pages/tools/summaries/[id].vue?macro=true";
import { default as indexYlj5fk33QjMeta } from "/vercel/path0/pages/tools/summaries/index.vue?macro=true";
import { default as _91id_93OQdnV3YkvMMeta } from "/vercel/path0/pages/tools/tasks/[id].vue?macro=true";
import { default as taskseiPXDKNpVOMeta } from "/vercel/path0/pages/tools/tasks.vue?macro=true";
import { default as verify_45emailTib6n1igWMMeta } from "/vercel/path0/pages/verify-email.vue?macro=true";
import { default as youtube_45video_45summarizer8AtzswdGpUMeta } from "/vercel/path0/pages/youtube-video-summarizer.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-you___es",
    path: "/about-you",
    meta: about_45youMVi4tHUAVMMeta || {},
    component: () => import("/vercel/path0/pages/about-you.vue").then(m => m.default || m)
  },
  {
    name: "about-you___en",
    path: "/en/about-you",
    meta: about_45youMVi4tHUAVMMeta || {},
    component: () => import("/vercel/path0/pages/about-you.vue").then(m => m.default || m)
  },
  {
    name: "admin-blogs___es",
    path: "/admin/blogs",
    meta: blogso4bmmwth6EMeta || {},
    component: () => import("/vercel/path0/pages/admin/blogs.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-blogs-id___es",
    path: ":id()",
    meta: _91id_93MrFb9P7oNRMeta || {},
    component: () => import("/vercel/path0/pages/admin/blogs/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-blogs___en",
    path: "/en/admin/blogs",
    meta: blogso4bmmwth6EMeta || {},
    component: () => import("/vercel/path0/pages/admin/blogs.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-blogs-id___en",
    path: ":id()",
    meta: _91id_93MrFb9P7oNRMeta || {},
    component: () => import("/vercel/path0/pages/admin/blogs/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-dashboard___es",
    path: "/admin/dashboard",
    meta: dashboardYi2jyZZMHDMeta || {},
    component: () => import("/vercel/path0/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "admin-dashboard___en",
    path: "/en/admin/dashboard",
    meta: dashboardYi2jyZZMHDMeta || {},
    component: () => import("/vercel/path0/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "admin-essays-id___es",
    path: "/admin/essays/:id()",
    meta: _91id_932hSgsR4JDDMeta || {},
    component: () => import("/vercel/path0/pages/admin/essays/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-essays-id___en",
    path: "/en/admin/essays/:id()",
    meta: _91id_932hSgsR4JDDMeta || {},
    component: () => import("/vercel/path0/pages/admin/essays/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-help-center___es",
    path: "/admin/help-center",
    meta: help_45centermUnX3GU4L6Meta || {},
    component: () => import("/vercel/path0/pages/admin/help-center.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-help-center-id___es",
    path: ":id()",
    meta: _91id_93FscL10BaqWMeta || {},
    component: () => import("/vercel/path0/pages/admin/help-center/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-help-center___en",
    path: "/en/admin/help-center",
    meta: help_45centermUnX3GU4L6Meta || {},
    component: () => import("/vercel/path0/pages/admin/help-center.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-help-center-id___en",
    path: ":id()",
    meta: _91id_93FscL10BaqWMeta || {},
    component: () => import("/vercel/path0/pages/admin/help-center/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-paraphrases-id___es",
    path: "/admin/paraphrases/:id()",
    meta: _91id_93XSAGQTKuM3Meta || {},
    component: () => import("/vercel/path0/pages/admin/paraphrases/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-paraphrases-id___en",
    path: "/en/admin/paraphrases/:id()",
    meta: _91id_93XSAGQTKuM3Meta || {},
    component: () => import("/vercel/path0/pages/admin/paraphrases/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-products-id-edit___es",
    path: "/admin/products/:id()/edit",
    meta: editpjBFXhILT2Meta || {},
    component: () => import("/vercel/path0/pages/admin/products/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-products-id-edit___en",
    path: "/en/admin/products/:id()/edit",
    meta: editpjBFXhILT2Meta || {},
    component: () => import("/vercel/path0/pages/admin/products/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-products-id-prices___es",
    path: "/admin/products/:id()/prices",
    meta: pricesEgjoEI5sKFMeta || {},
    component: () => import("/vercel/path0/pages/admin/products/[id]/prices.vue").then(m => m.default || m)
  },
  {
    name: "admin-products-id-prices___en",
    path: "/en/admin/products/:id()/prices",
    meta: pricesEgjoEI5sKFMeta || {},
    component: () => import("/vercel/path0/pages/admin/products/[id]/prices.vue").then(m => m.default || m)
  },
  {
    name: "admin-products-create___es",
    path: "/admin/products/create",
    meta: create8LkXyb2OcpMeta || {},
    component: () => import("/vercel/path0/pages/admin/products/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-products-create___en",
    path: "/en/admin/products/create",
    meta: create8LkXyb2OcpMeta || {},
    component: () => import("/vercel/path0/pages/admin/products/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-products___es",
    path: "/admin/products",
    meta: indexr4iBpyk8AWMeta || {},
    component: () => import("/vercel/path0/pages/admin/products/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-products___en",
    path: "/en/admin/products",
    meta: indexr4iBpyk8AWMeta || {},
    component: () => import("/vercel/path0/pages/admin/products/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-quizzes-id___es",
    path: "/admin/quizzes/:id()",
    meta: _91id_93kaSEixcauRMeta || {},
    component: () => import("/vercel/path0/pages/admin/quizzes/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-quizzes-id___en",
    path: "/en/admin/quizzes/:id()",
    meta: _91id_93kaSEixcauRMeta || {},
    component: () => import("/vercel/path0/pages/admin/quizzes/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-summaries-id___es",
    path: "/admin/summaries/:id()",
    meta: _91id_93zYe9mZvkwRMeta || {},
    component: () => import("/vercel/path0/pages/admin/summaries/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-summaries-id___en",
    path: "/en/admin/summaries/:id()",
    meta: _91id_93zYe9mZvkwRMeta || {},
    component: () => import("/vercel/path0/pages/admin/summaries/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-texts-id___es",
    path: "/admin/texts/:id()",
    meta: _91id_933pwRGkEYc6Meta || {},
    component: () => import("/vercel/path0/pages/admin/texts/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-texts-id___en",
    path: "/en/admin/texts/:id()",
    meta: _91id_933pwRGkEYc6Meta || {},
    component: () => import("/vercel/path0/pages/admin/texts/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-texts___es",
    path: "/admin/texts",
    meta: indexgo1pS4yjOkMeta || {},
    component: () => import("/vercel/path0/pages/admin/texts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-texts___en",
    path: "/en/admin/texts",
    meta: indexgo1pS4yjOkMeta || {},
    component: () => import("/vercel/path0/pages/admin/texts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id___es",
    path: "/admin/users/:id()",
    meta: _91id_939f4NtHSsVaMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "admin-users-id-essays___es",
    path: "essays",
    component: () => import("/vercel/path0/pages/admin/users/[id]/essays.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id-paraphrases___es",
    path: "paraphrases",
    component: () => import("/vercel/path0/pages/admin/users/[id]/paraphrases.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id-quizzes___es",
    path: "quizzes",
    component: () => import("/vercel/path0/pages/admin/users/[id]/quizzes.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id-summaries___es",
    path: "summaries",
    component: () => import("/vercel/path0/pages/admin/users/[id]/summaries.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-users-id___en",
    path: "/en/admin/users/:id()",
    meta: _91id_939f4NtHSsVaMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "admin-users-id-essays___en",
    path: "essays",
    component: () => import("/vercel/path0/pages/admin/users/[id]/essays.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id-paraphrases___en",
    path: "paraphrases",
    component: () => import("/vercel/path0/pages/admin/users/[id]/paraphrases.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id-quizzes___en",
    path: "quizzes",
    component: () => import("/vercel/path0/pages/admin/users/[id]/quizzes.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id-summaries___en",
    path: "summaries",
    component: () => import("/vercel/path0/pages/admin/users/[id]/summaries.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-users-analytics___es",
    path: "/admin/users/analytics",
    meta: analyticsetOKEJ7f5gMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/analytics.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-analytics___en",
    path: "/en/admin/users/analytics",
    meta: analyticsetOKEJ7f5gMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/analytics.vue").then(m => m.default || m)
  },
  {
    name: "admin-users___es",
    path: "/admin/users",
    meta: indexgxZ1ikbUBwMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users___en",
    path: "/en/admin/users",
    meta: indexgxZ1ikbUBwMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "ai-essay-writer___es",
    path: "/creador-de-ensayos",
    meta: ai_45essay_45writerLWlVCFQIrnMeta || {},
    component: () => import("/vercel/path0/pages/ai-essay-writer.vue").then(m => m.default || m)
  },
  {
    name: "ai-essay-writer___en",
    path: "/en/ai-essay-writer",
    meta: ai_45essay_45writerLWlVCFQIrnMeta || {},
    component: () => import("/vercel/path0/pages/ai-essay-writer.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___es",
    path: "/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___es",
    path: "/blog",
    meta: indexmfxAx2eWMBMeta || {},
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/en/blog",
    meta: indexmfxAx2eWMBMeta || {},
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pricing___es",
    path: "/checkout/pricing",
    meta: pricingt2pDtGfDaSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/pricing.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pricing___en",
    path: "/en/checkout/pricing",
    meta: pricingt2pDtGfDaSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/pricing.vue").then(m => m.default || m)
  },
  {
    name: "checkout-return___es",
    path: "/checkout/return",
    meta: returnOojyHHiAtSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/return.vue").then(m => m.default || m)
  },
  {
    name: "checkout-return___en",
    path: "/en/checkout/return",
    meta: returnOojyHHiAtSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/return.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___es",
    path: "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "documents___es",
    path: "/documentos",
    meta: documentsQsTdxIaiRLMeta || {},
    component: () => import("/vercel/path0/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "documents___en",
    path: "/en/documents",
    meta: documentsQsTdxIaiRLMeta || {},
    component: () => import("/vercel/path0/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "files-slug___es",
    path: "/files/:slug()",
    component: () => import("/vercel/path0/pages/files/[slug].vue").then(m => m.default || m)
  },
  {
    name: "files-slug___en",
    path: "/en/files/:slug()",
    component: () => import("/vercel/path0/pages/files/[slug].vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___es",
    path: "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___es",
    path: "/iniciar-sesion",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___es",
    path: "/maintenance",
    component: () => import("/vercel/path0/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/en/maintenance",
    component: () => import("/vercel/path0/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "parafraquiz___es",
    path: "/parafraquiz",
    meta: parafraquizTeZZPJGDGwMeta || {},
    component: () => import("/vercel/path0/pages/parafraquiz.vue").then(m => m.default || m)
  },
  {
    name: "parafraquiz___en",
    path: "/en/parafraquiz",
    meta: parafraquizTeZZPJGDGwMeta || {},
    component: () => import("/vercel/path0/pages/parafraquiz.vue").then(m => m.default || m)
  },
  {
    name: "paraphrasing-tool___es",
    path: "/parafrasear",
    meta: paraphrasing_45toolp1sA3nuR2fMeta || {},
    component: () => import("/vercel/path0/pages/paraphrasing-tool.vue").then(m => m.default || m)
  },
  {
    name: "paraphrasing-tool___en",
    path: "/en/paraphrasing-tool",
    meta: paraphrasing_45toolp1sA3nuR2fMeta || {},
    component: () => import("/vercel/path0/pages/paraphrasing-tool.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token___es",
    path: "/password-reset/:token()",
    meta: _91token_93hTDxOgyFeIMeta || {},
    component: () => import("/vercel/path0/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token___en",
    path: "/en/password-reset/:token()",
    meta: _91token_93hTDxOgyFeIMeta || {},
    component: () => import("/vercel/path0/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "policies-slug___es",
    path: "/politicas/:slug()",
    meta: _91slug_93un7ONJp8l3Meta || {},
    component: () => import("/vercel/path0/pages/policies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "policies-slug___en",
    path: "/en/policies/:slug()",
    meta: _91slug_93un7ONJp8l3Meta || {},
    component: () => import("/vercel/path0/pages/policies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pricing___es",
    path: "/precios",
    meta: pricingIYNdvfoSO6Meta || {},
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___en",
    path: "/en/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "register___es",
    path: "/registro",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile___es",
    path: "/settings/profile",
    meta: profile3g7qqlrA4oMeta || {},
    component: () => import("/vercel/path0/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile___en",
    path: "/en/settings/profile",
    meta: profile3g7qqlrA4oMeta || {},
    component: () => import("/vercel/path0/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "texts-id___es",
    path: "/texts/:id()",
    component: () => import("/vercel/path0/pages/texts/[id].vue").then(m => m.default || m)
  },
  {
    name: "texts-id___en",
    path: "/en/texts/:id()",
    component: () => import("/vercel/path0/pages/texts/[id].vue").then(m => m.default || m)
  },
  {
    name: "to-do-list___es",
    path: "/to-do-list",
    meta: to_45do_45list7kjsrqXPBTMeta || {},
    component: () => import("/vercel/path0/pages/to-do-list.vue").then(m => m.default || m)
  },
  {
    name: "to-do-list___en",
    path: "/en/to-do-list",
    meta: to_45do_45list7kjsrqXPBTMeta || {},
    component: () => import("/vercel/path0/pages/to-do-list.vue").then(m => m.default || m)
  },
  {
    name: "tools-documents___es",
    path: "/tools/documents",
    meta: documentsvKKHHetvDuMeta || {},
    component: () => import("/vercel/path0/pages/tools/documents.vue").then(m => m.default || m),
    children: [
  {
    name: "tools-documents-id___es",
    path: ":id()",
    meta: _91id_93RRyDBnfIIBMeta || {},
    component: () => import("/vercel/path0/pages/tools/documents/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-documents___en",
    path: "/en/tools/documents",
    meta: documentsvKKHHetvDuMeta || {},
    component: () => import("/vercel/path0/pages/tools/documents.vue").then(m => m.default || m),
    children: [
  {
    name: "tools-documents-id___en",
    path: ":id()",
    meta: _91id_93RRyDBnfIIBMeta || {},
    component: () => import("/vercel/path0/pages/tools/documents/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-essays-id___es",
    path: "/tools/essays/:id()",
    meta: _91id_93YoAux6dZqPMeta || {},
    component: () => import("/vercel/path0/pages/tools/essays/[id].vue").then(m => m.default || m)
  },
  {
    name: "tools-essays-id___en",
    path: "/en/tools/essays/:id()",
    meta: _91id_93YoAux6dZqPMeta || {},
    component: () => import("/vercel/path0/pages/tools/essays/[id].vue").then(m => m.default || m)
  },
  {
    name: "tools-essays___es",
    path: "/tools/essays",
    meta: indexp4ty5M4ppvMeta || {},
    component: () => import("/vercel/path0/pages/tools/essays/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-essays___en",
    path: "/en/tools/essays",
    meta: indexp4ty5M4ppvMeta || {},
    component: () => import("/vercel/path0/pages/tools/essays/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-essays-table-of-contents-id___es",
    path: "/tools/essays/table-of-contents/:id()",
    meta: _91id_93EDdzHJUTJaMeta || {},
    component: () => import("/vercel/path0/pages/tools/essays/table-of-contents/[id].vue").then(m => m.default || m)
  },
  {
    name: "tools-essays-table-of-contents-id___en",
    path: "/en/tools/essays/table-of-contents/:id()",
    meta: _91id_93EDdzHJUTJaMeta || {},
    component: () => import("/vercel/path0/pages/tools/essays/table-of-contents/[id].vue").then(m => m.default || m)
  },
  {
    name: "tools-paraphrases___es",
    path: "/tools/paraphrases",
    meta: paraphrasesN69RkGnKHeMeta || {},
    component: () => import("/vercel/path0/pages/tools/paraphrases.vue").then(m => m.default || m),
    children: [
  {
    name: "tools-paraphrases-id___es",
    path: ":id()",
    meta: _91id_93wchdGemELqMeta || {},
    component: () => import("/vercel/path0/pages/tools/paraphrases/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-paraphrases___en",
    path: "/en/tools/paraphrases",
    meta: paraphrasesN69RkGnKHeMeta || {},
    component: () => import("/vercel/path0/pages/tools/paraphrases.vue").then(m => m.default || m),
    children: [
  {
    name: "tools-paraphrases-id___en",
    path: ":id()",
    meta: _91id_93wchdGemELqMeta || {},
    component: () => import("/vercel/path0/pages/tools/paraphrases/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-quizzes-id___es",
    path: "/tools/quizzes/:id()",
    meta: _91id_93GkxXVuhoCWMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "tools-quizzes-id-questions-questionId___es",
    path: "questions/:questionId()",
    meta: _91questionId_939L2dBO6WsbMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/[id]/questions/[questionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-quizzes-id___en",
    path: "/en/tools/quizzes/:id()",
    meta: _91id_93GkxXVuhoCWMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "tools-quizzes-id-questions-questionId___en",
    path: "questions/:questionId()",
    meta: _91questionId_939L2dBO6WsbMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/[id]/questions/[questionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-quizzes-slug-practice-play___es",
    path: "/tools/quizzes/:slug()/practice/play",
    meta: playeO9f7nZ3BKMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/[slug]/practice/play.vue").then(m => m.default || m)
  },
  {
    name: "tools-quizzes-slug-practice-play___en",
    path: "/en/tools/quizzes/:slug()/practice/play",
    meta: playeO9f7nZ3BKMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/[slug]/practice/play.vue").then(m => m.default || m)
  },
  {
    name: "tools-quizzes-slug-practice-results___es",
    path: "/tools/quizzes/:slug()/practice/results",
    meta: results1qah9fzBAOMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/[slug]/practice/results.vue").then(m => m.default || m)
  },
  {
    name: "tools-quizzes-slug-practice-results___en",
    path: "/en/tools/quizzes/:slug()/practice/results",
    meta: results1qah9fzBAOMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/[slug]/practice/results.vue").then(m => m.default || m)
  },
  {
    name: "tools-quizzes___es",
    path: "/tools/quizzes",
    meta: indexaBD9p6HNbMMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/index.vue").then(m => m.default || m),
    children: [
  {
    name: "tools-quizzes-index-all___es",
    path: "all",
    meta: allPUsaa5tpNiMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/index/all.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-quizzes___en",
    path: "/en/tools/quizzes",
    meta: indexaBD9p6HNbMMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/index.vue").then(m => m.default || m),
    children: [
  {
    name: "tools-quizzes-index-all___en",
    path: "all",
    meta: allPUsaa5tpNiMeta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/index/all.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-quizzes-question-types-id___es",
    path: "/tools/quizzes/question-types/:id()",
    meta: _91id_93xMEhnw07p8Meta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/question-types/[id].vue").then(m => m.default || m)
  },
  {
    name: "tools-quizzes-question-types-id___en",
    path: "/en/tools/quizzes/question-types/:id()",
    meta: _91id_93xMEhnw07p8Meta || {},
    component: () => import("/vercel/path0/pages/tools/quizzes/question-types/[id].vue").then(m => m.default || m)
  },
  {
    name: "tools-summaries-id___es",
    path: "/tools/summaries/:id()",
    meta: _91id_93xqaQkivPd2Meta || {},
    component: () => import("/vercel/path0/pages/tools/summaries/[id].vue").then(m => m.default || m)
  },
  {
    name: "tools-summaries-id___en",
    path: "/en/tools/summaries/:id()",
    meta: _91id_93xqaQkivPd2Meta || {},
    component: () => import("/vercel/path0/pages/tools/summaries/[id].vue").then(m => m.default || m)
  },
  {
    name: "tools-summaries___es",
    path: "/tools/summaries",
    meta: indexYlj5fk33QjMeta || {},
    component: () => import("/vercel/path0/pages/tools/summaries/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-summaries___en",
    path: "/en/tools/summaries",
    meta: indexYlj5fk33QjMeta || {},
    component: () => import("/vercel/path0/pages/tools/summaries/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-tasks___es",
    path: "/tools/tasks",
    meta: taskseiPXDKNpVOMeta || {},
    component: () => import("/vercel/path0/pages/tools/tasks.vue").then(m => m.default || m),
    children: [
  {
    name: "tools-tasks-id___es",
    path: ":id()",
    meta: _91id_93OQdnV3YkvMMeta || {},
    component: () => import("/vercel/path0/pages/tools/tasks/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-tasks___en",
    path: "/en/tools/tasks",
    meta: taskseiPXDKNpVOMeta || {},
    component: () => import("/vercel/path0/pages/tools/tasks.vue").then(m => m.default || m),
    children: [
  {
    name: "tools-tasks-id___en",
    path: ":id()",
    meta: _91id_93OQdnV3YkvMMeta || {},
    component: () => import("/vercel/path0/pages/tools/tasks/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "verify-email___es",
    path: "/verify-email",
    meta: verify_45emailTib6n1igWMMeta || {},
    component: () => import("/vercel/path0/pages/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "verify-email___en",
    path: "/en/verify-email",
    meta: verify_45emailTib6n1igWMMeta || {},
    component: () => import("/vercel/path0/pages/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "youtube-video-summarizer___es",
    path: "/resumidor-de-videos-de-youtube",
    meta: youtube_45video_45summarizer8AtzswdGpUMeta || {},
    component: () => import("/vercel/path0/pages/youtube-video-summarizer.vue").then(m => m.default || m)
  },
  {
    name: "youtube-video-summarizer___en",
    path: "/en/youtube-video-summarizer",
    meta: youtube_45video_45summarizer8AtzswdGpUMeta || {},
    component: () => import("/vercel/path0/pages/youtube-video-summarizer.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/generador-de-cuestionarios-ia",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/creador-de-cuestionarios",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/en/ai-writer",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/en/ai-quiz-maker",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/register",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/login",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/pricing",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/de/login",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/de/register",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/de/pricing",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/it/login",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/it/register",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/it/pricing",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/id/login",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/id/register",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/id/pricing",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/fr/login",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/fr/register",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/fr/pricing",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/nl/login",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/nl/register",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/nl/pricing",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/es-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  }
]