import { FetchError } from "ofetch";
export default defineNuxtPlugin((nuxtApp) => {
  const user = useSanctumUser<User>();

  const localePath = useLocalePath();
  nuxtApp.hook("vue:error", (error: any) => {
    if (!(error instanceof FetchError)) {
      // useBugsnag().notify(error);
      throw error;
    }
    const status = error.response?.status ?? -1;

    if ([401, 419].includes(status)) {
      // Or
      user.value = null;
      navigateTo(localePath("login"));
    }

    if ([403].includes(status)) {
      navigateTo(localePath("login"));
    }

    if ([409].includes(status)) {
      navigateTo(localePath("/verify-email"));
    }
  });
});
