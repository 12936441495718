<script lang="ts" setup>
const { locale } = useI18n();
const { limits } = useLimits();

useHead({
  script: [
    {
      innerHTML: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-617182852');
    `,
    },
  ],
});
</script>

<template>
  <div>
    <NuxtLoadingIndicator
      color="repeating-linear-gradient(to right,#6e20e1 0%,#8045fd 50%,#6e20e1 100%)"
      :height="3"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <HelpCenterHelpButton
      v-if="limits?.help_center_enabled == 1"
      class="fixed bottom-4 right-4 z-[100]"
    />

    <CookieControl v-if="locale === 'es'" locale="es" />
    <CookieControl v-else locale="en" />
    <UNotifications :ui="{ strategy: 'override' }" />
  </div>
</template>
