import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ucMK8VhJjR from "/vercel/path0/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_tMGwffvjBc from "/vercel/path0/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import titles_dw2T9lEw4h from "/vercel/path0/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import plugin_vfCt6Q18Tc from "/vercel/path0/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_65xnz9UtDI from "/vercel/path0/node_modules/nuxt-zod-i18n/dist/runtime/plugin.js";
import plugin_C2QvvtgUo3 from "/vercel/path0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_Jozdw60ZsE from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import motion_jmBwdqbgTJ from "/vercel/path0/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import slideovers_X7whXyrIWR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_Eg4DPJPcY6 from "/vercel/path0/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_1aypKNZ222 from "/vercel/path0/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/vercel/path0/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import auth_vT9JWWT9pN from "/vercel/path0/plugins/auth.ts";
import error_handler_kEP5FliEXj from "/vercel/path0/plugins/error-handler.ts";
import init_globals_rWKKkgSDqN from "/vercel/path0/plugins/init-globals.ts";
import sanctum_config_jf1sbnmSy2 from "/vercel/path0/plugins/sanctum-config.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ucMK8VhJjR,
  plugin_client_UYiXMU8ZyN,
  plugin_tMGwffvjBc,
  titles_dw2T9lEw4h,
  plugin_vfCt6Q18Tc,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_65xnz9UtDI,
  plugin_C2QvvtgUo3,
  plugin_w2VlvAEcpa,
  plugin_Jozdw60ZsE,
  motion_jmBwdqbgTJ,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  scrollbars_client_Eg4DPJPcY6,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  auth_vT9JWWT9pN,
  error_handler_kEP5FliEXj,
  init_globals_rWKKkgSDqN,
  sanctum_config_jf1sbnmSy2
]