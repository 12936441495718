import type { FetchContext } from "ofetch";
import type { ConsolaInstance } from "consola";
import type { NuxtApp } from "#app";

export default defineAppConfig({
  ui: {
    primary: "electric-violet",
    gray: "slate",
    button: {
      rounded: "rounded-lg",
      default: {
        loadingIcon: "i-ph-circle-notch",
        size: "xl",
      },
    },
    notifications: {
      // Show toasts at the top right of the screen
      position:
        "left-1/2 transform -translate-x-1/2 bottom-1/2 -translate-y-1/2",
    },
    textarea: {
      rounded: "rounded-xl",
    },
    avatar: {
      rounded: "object-cover rounded-full",
    },
    skeleton: {
      base: "animate-skeleton",
      background:
        "bg-gradient-to-r from-gray-200 via-gray-50 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-800 bg-[length:400%]",
    },
    header: {
      wrapper: "border-b-0",
      links: {
        base: "text-base/6 font-medium",
      },
    },
    landing: {
      section: {
        wrapper: "py-12 sm:py-20",
        container: "gap-12 sm:gap-y-24",
        title: "text-gray-600",
      },
      hero: {
        wrapper: "py-12 sm:py-20 md:py-32 relative",
        title: "text-4xl sm:text-5xl font-bold text-gray-600 font-semibold",
      },
      testimonial: {
        icon: {
          base: "text-electric-violet-500",
        },
      },
    },
    pricing: {
      card: {
        title: "text-primary sm:text-base",
      },
    },
    dashboard: {
      navbar: {
        title: "text-xl",
        wrapper: "border-b-0",
      },
      panel: {
        wrapper: "border-gray-100",
      },
    },
    variables: {
      header: {
        height: "5rem",
      },
    },
  },
  sanctum: {
    interceptors: {
      onRequest: (
        app: NuxtApp,
        ctx: FetchContext,
        _logger: ConsolaInstance,
      ) => {
        if (import.meta.server) {
          const headers = useRequestHeaders();
          ctx.options.headers = {
            ...ctx.options.headers,
            "X-Forwarded-For": headers["x-forwarded-for"],
          };

          if (app.$i18n?.localeProperties?.value?.code) {
            ctx.options.headers = {
              ...ctx.options.headers,
              "Parafrasist-Language": app.$i18n.localeProperties.value.code,
            };
          }
        } else {
          ctx.options.headers = {
            ...ctx.options.headers,
            "Parafrasist-Language": app.$i18n.localeProperties.value.code,
          };
        }

        return Promise.resolve();
      },
    },
  },
});
