export type ValidationError = {
  path: string;
  message: string;
};
export type ValidationErrors = Array<ValidationError>;

export type UseSubmitOptions = {
  onSuccess?: (result: any) => any;
  onError?: (error: any) => any;
};

export function useSubmit<T>(
  fetchable: () => Promise<T>,
  options: UseSubmitOptions = {},
) {
  const validationErrors = ref<ValidationErrors>([]);
  const error = ref<Error | null | any>(null);
  const inProgress = ref(false);
  const succeeded = ref<Boolean | null>(null);

  async function submit() {
    validationErrors.value = [];
    error.value = null;
    inProgress.value = true;
    succeeded.value = null;

    try {
      const data = await fetchable();
      succeeded.value = true;
      options?.onSuccess?.(data);
      return data;
    } catch (e: any) {
      error.value = e;
      succeeded.value = false;
      if (e.data?.errors) {
        validationErrors.value = Object.entries(e.data?.errors).map(
          ([key, value]) => ({
            path: key,
            message: (value as string[])[0],
          }),
        );
      } else {
        validationErrors.value = [];
      }
      options?.onError?.(e);

      // if (e.response?.status !== 422) throw e;
    } finally {
      inProgress.value = false;
    }
  }

  return {
    submit,
    inProgress,
    succeeded,
    validationErrors,
    error,
  };
}
