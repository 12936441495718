import { VueRenderer, type Range } from "@tiptap/vue-3";
import { Editor as CoreEditor, Extension } from "@tiptap/core";
import { Suggestion } from "@tiptap/suggestion";
import tippy from "tippy.js";

import type {
  SuggestionProps,
  SuggestionKeyDownProps,
} from "@tiptap/suggestion";
import { SLASH_ITEMS } from "./slashItems";
import SlashMenu from "~/components/Editor/Menus/SlashMenu.vue";

const extensionName = "slashCommand";

export default Extension.create({
  name: extensionName,
  addOptions() {
    return {
      imagesEnabled: false,
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        char: "/",
        allowSpaces: true,
        startOfLine: true,
        command: ({
          editor,
          range,
          props,
        }: {
          editor: CoreEditor;
          range: Range;
          props: any;
        }) => {
          props.command({ editor, range });
        },
        items: ({ query }: { query: string }) =>
          SLASH_ITEMS.filter((item) => {
            if (!this.options.imagesEnabled && item.is_media) {
              return false;
            }

            return item.title.toLowerCase().startsWith(query.toLowerCase());
          }),
        render: () => {
          let component: VueRenderer;
          let popup: any;

          return {
            onStart: (props: SuggestionProps) => {
              component = new VueRenderer(SlashMenu, {
                props,
                editor: props.editor,
              });
              if (!props.clientRect) {
                return;
              }

              popup = tippy("body", {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "bottom-start",
                arrow: false,
                theme: "chicago",
              });
            },

            onUpdate(props: SuggestionProps) {
              component.updateProps(props);

              if (!props.clientRect) {
                return;
              }

              popup[0].setProps({
                getReferenceClientRect: props.clientRect,
              });
            },

            onKeyDown(props: SuggestionKeyDownProps) {
              if (props.event.key === "Escape") {
                popup[0].hide();

                return true;
              }

              return component.ref?.onKeyDown(props);
            },

            onExit() {
              popup[0].destroy();
              component.destroy();
            },
          };
        },
      }),
    ];
  },
});
