import { StarterKit } from "@tiptap/starter-kit";
import { Underline } from "@tiptap/extension-underline";
import { Link } from "@tiptap/extension-link";
import { TaskItem } from "@tiptap/extension-task-item";
import { TaskList } from "@tiptap/extension-task-list";
import { Placeholder } from "@tiptap/extension-placeholder";
import { TextAlign } from "@tiptap/extension-text-align";
import Focus from "@tiptap/extension-focus";
import { CharacterCount } from "@tiptap/extension-character-count";
import ImageUpload from "~/tiptap-extensions/ImageUpload/ImageUpload";
import SlashCommand from "~/tiptap-extensions/SlashCommand/SlashCommand";
import ImageBlock from "~/tiptap-extensions/ImageBlock/ImageBlock";

export const useTiptapExtensions = (imagesEnabled: boolean) => {
  const extensions: any = [
    StarterKit,
    Underline,
    Link.configure({
      openOnClick: true,
    }),
    TaskItem,
    TaskList,
    TextAlign.configure({
      alignments: ["left", "center", "right", "justify"],
      types: ["heading", "paragraph"],
    }),
    Placeholder.configure({
      placeholder: "Escribe o usa / para agregar contenido...",
      showOnlyCurrent: true,
    }),
    Focus,
    CharacterCount.configure({
      limit: 200000,
    }),
  ];
  if (imagesEnabled) {
    extensions.push(ImageUpload);
    extensions.push(ImageBlock);
  }

  extensions.push(
    SlashCommand.configure({
      imagesEnabled,
    }),
  );

  return extensions;
};
