<script lang="ts" setup>
import { BubbleMenu, Editor } from "@tiptap/vue-3";
import { EditorState } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";
import { isTextSelected } from "#imports";
const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
});

type TiptapButton = {
  icon: string;
  action: () => void;
  isActive?: () => boolean;
  helpText: string;
};

const { t } = useI18n();

const buttons: Array<TiptapButton> = [
  {
    icon: "i-ph-text-b-bold",
    action: () => props.editor.chain().focus().toggleBold().run(),
    isActive: () => props.editor.isActive("bold"),
    helpText: t("bubble_menu.bold"),
  },
  {
    icon: "i-ph-text-italic-bold",
    action: () => props.editor.chain().focus().toggleItalic().run(),
    isActive: () => props.editor.isActive("italic"),
    helpText: t("bubble_menu.italic"),
  },
  {
    icon: "i-ph-text-strikethrough-bold",
    action: () => props.editor.chain().focus().toggleStrike().run(),
    isActive: () => props.editor.isActive("strike"),
    helpText: t("bubble_menu.strike"),
  },
  {
    icon: "i-ph-text-underline-bold",
    action: () => props.editor.chain().focus().toggleUnderline().run(),
    isActive: () => props.editor.isActive("underline"),
    helpText: t("bubble_menu.underline"),
  },
  {
    icon: "i-ph-text-align-left-bold",
    action: () => props.editor.chain().focus().setTextAlign("left").run(),
    isActive: () => props.editor.isActive({ textAlign: "left" }),
    helpText: t("bubble_menu.left"),
  },
  {
    icon: "i-ph-text-align-center-bold",
    action: () => props.editor.chain().focus().setTextAlign("center").run(),
    isActive: () => props.editor.isActive({ textAlign: "center" }),
    helpText: t("bubble_menu.center"),
  },
  {
    icon: "i-ph-text-align-right-bold",
    action: () => props.editor.chain().focus().setTextAlign("right").run(),
    isActive: () => props.editor.isActive({ textAlign: "right" }),
    helpText: t("bubble_menu.right"),
  },
  {
    icon: "i-ph-text-align-justify-bold",
    action: () => props.editor.chain().focus().setTextAlign("justify").run(),
    isActive: () => props.editor.isActive({ textAlign: "justify" }),
    helpText: t("bubble_menu.justify"),
  },
];
export interface ShouldShowProps {
  editor?: Editor;
  view: EditorView;
  state?: EditorState;
  oldState?: EditorState;
  from?: number;
  to?: number;
}

const shouldShow = ({ view }: ShouldShowProps) => {
  if (!view) {
    return false;
  }

  if (isCustomNodeSelected(props.editor)) {
    return false;
  }
  return isTextSelected({ editor: props.editor });
};

const shouldShowNodePicker = () => {
  return (
    props.editor.isActive("paragraph") ||
    props.editor.isActive("orderedList") ||
    props.editor.isActive("bulletList") ||
    props.editor.isActive("taskList") ||
    props.editor.isActive("heading") ||
    props.editor.isActive("taskList")
  );
};
</script>

<template>
  <bubble-menu
    v-if="editor"
    :editor="editor"
    plugin-key="bubbleMenu"
    :should-show="({ view }) => shouldShow({ view })"
    :tippy-options="{
      duration: 100,
      maxWidth: 800,
      theme: 'chicago',
      arrow: false,
    }"
  >
    <div class="rounded-xl flex gap-2 px-1">
      <EditorMenusNodeTypePicker
        v-if="editor && shouldShowNodePicker()"
        :editor="editor"
      />
      <template v-for="(button, index) in buttons" :key="index">
        <UTooltip
          :ui="{
            background: 'bg-chicago-500',
            color: 'text-white',
          }"
          :text="button.helpText"
          color="chicago"
        >
          <EditorMenusButtonMenu
            :icon="button.icon"
            :is-active="button.isActive"
            @click="button.action"
          />
        </UTooltip>
      </template>
      <EditorMenusEditLinkPopover v-if="editor" :editor="editor" />
    </div>
  </bubble-menu>
</template>
