<script lang="ts" setup>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
const props = defineProps(nodeViewProps);

const src = props.node.attrs.src;
// const wrapperClassName = cn(
//   node.attrs.align === "left" ? "ml-0" : "ml-auto",
//   node.attrs.align === "right" ? "mr-0" : "mr-auto",
//   node.attrs.align === "center" && "mx-auto",
// );
// Transform above react code to Vue
const wrapperClassName = computed(() => {
  return {
    "mr-auto": props.node.attrs.align === "left",
    "ml-auto": props.node.attrs.align === "right",
    "mx-auto": props.node.attrs.align === "center",
  };
});

const onClick = () => {
  props.editor.commands.setNodeSelection(props.getPos());
};
</script>

<template>
  <NodeViewWrapper class="node-imageBlock">
    <div :class="wrapperClassName" :style="'width:' + node.attrs.width">
      <div :contenteditable="false">
        <img class="block" :src="src" alt="" @click="onClick" />
      </div>
    </div>
  </NodeViewWrapper>
</template>

<style></style>
