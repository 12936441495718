<script lang="ts" setup>
import { BubbleMenu, Editor, isNodeActive } from "@tiptap/vue-3";
const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
});

const shouldShow = () => {
  return (
    isNodeActive(props.editor.view.state, "imageBlock") &&
    props.editor.isEditable
  );
};

const onAlignImageLeft = () => {
  props.editor
    .chain()
    .focus(undefined, { scrollIntoView: false })
    .setImageBlockAlign("left")
    .run();
};

const onAlignImageCenter = () => {
  props.editor
    .chain()
    .focus(undefined, { scrollIntoView: false })
    .setImageBlockAlign("center")
    .run();
};

const onAlignImageRight = () => {
  props.editor
    .chain()
    .focus(undefined, { scrollIntoView: false })
    .setImageBlockAlign("right")
    .run();
};

const onWidthChange = (value: number) => {
  if (!value) return;
  props.editor
    .chain()
    .focus(undefined, { scrollIntoView: false })
    .setImageBlockWidth(value)
    .run();
};
</script>

<template>
  <BubbleMenu
    v-if="editor"
    :should-show="shouldShow"
    :editor="editor"
    plugin-key="imageblockMenu"
    :tippy-options="{
      duration: 100,
      maxWidth: 800,
    }"
  >
    <div class="bg-chicago-500 p-1 rounded-lg flex items-center space-x-2 w-80">
      <EditorMenusButtonMenu
        icon="i-ph-text-align-left-bold"
        :is-active="() => editor.isActive('imageBlock', { align: 'left' })"
        @click="onAlignImageLeft"
      />
      <EditorMenusButtonMenu
        icon="i-ph-text-align-center-bold"
        :is-active="() => editor.isActive('imageBlock', { align: 'center' })"
        @click="onAlignImageCenter"
      />
      |
      <EditorMenusButtonMenu
        icon="i-ph-text-align-right-bold"
        :is-active="() => editor.isActive('imageBlock', { align: 'right' })"
        @click="onAlignImageRight"
      />
      <URange
        :model-value="
          editor.getAttributes('imageBlock').width?.replace('%', '')
        "
        :min="25"
        :max="100"
        @update:model-value="onWidthChange"
      />
      <span class="text-white">
        {{ editor.getAttributes("imageBlock").width }}
      </span>
    </div>
  </BubbleMenu>
</template>

<style></style>
