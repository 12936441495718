export default defineNuxtPlugin(async () => {
  const { products } = useProducts();
  const { limits } = useLimits();
  const route = useRoute();
  const sanctumClient = useSanctumClient();

  const { data, error } = await useAsyncData("globals", () =>
    sanctumClient("/globals"),
  );

  if (error.value) {
    if (error.value.statusCode === 503 && route.path !== "/maintenance") {
      await navigateTo("/maintenance");
    }

    if (route.path !== "/maintenance") {
      throw createError(error.value);
    }
  }

  if (data.value) {
    products.value = data.value.products;
    limits.value = data.value.limits;

    if (route.path === "/maintenance") {
      await navigateTo("/");
    }
  }
});
