<script lang="ts" setup></script>

<template>
  <ULink
    to="https://wa.me/17073145322"
    class="w-full inline-block"
    target="_blank"
  >
    <UCard>
      <template #header>
        <div class="flex items-center justify-center gap-2">
          <Icon name="i-ph-whatsapp-logo" class="text-4xl text-gray-500" />
          <div class="flex flex-col text-center">
            <p class="font-bold">{{ $t("whatsapp_card.title") }}</p>
            <span class="text-sm text-gray-500">
              {{ $t("whatsapp_card.description") }}
            </span>
          </div>
        </div>
      </template>
    </UCard>
  </ULink>
</template>

<style></style>
