export const useLimits = () => {
  const sanctumClient = useSanctumClient();

  const limits = useNuxtData<Limits>("limits").data;
  const fetchLimits = () =>
    // Fetch limits
    useAsyncData<Limits>("limits", () => sanctumClient("/limits", {}));

  return {
    fetchLimits,
    limits,
  };
};
