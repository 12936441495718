<script lang="ts" setup>
import { useDebounceFn } from "@vueuse/shared";

const sanctumClient = useSanctumClient();
const open = ref(false);
const openSearch = ref(false);
const { data: helpCenterCategories } = useAsyncData(() =>
  sanctumClient<Array<HelpCenterCategory>>("/help-center", {}),
);

const postSelected = ref<HelpCenter | null>(null);
const searchPosts = ref<HelpCenter[]>([]);
const query = ref("");
const typing = ref(false);
const onInput = () => {
  typing.value = true;
  search();
};

const search = useDebounceFn(() => {
  if (query.value === "") {
    searchPosts.value = [];
    typing.value = false;
    openSearch.value = false;
    return;
  }

  submit();
  typing.value = false;
}, 500);

const { submit, inProgress } = useSubmit(
  () =>
    sanctumClient<HelpCenter>("/help-center/search", {
      params: {
        query: query.value,
      },
    }),
  {
    onSuccess: (response: HelpCenter[]) => {
      searchPosts.value = response;
      openSearch.value = true;
    },
  },
);

const onPostSelected = (post: HelpCenter) => {
  postSelected.value = post;
  query.value = "";
  searchPosts.value = [];
};
</script>

<template>
  <div>
    <UPopover
      v-model:open="open"
      :ui="{
        width: 'w-11/12 lg:w-1/3',
        container: 'z-[100]',
      }"
      :popper="{
        placement: 'top-end',
        strategy: 'fixed',
      }"
    >
      <UButton
        icon="i-ph-question"
        :ui="{ rounded: 'rounded-full' }"
        size="xl"
        :label="$t('floating_button.label')"
        class="shadow-lg"
      />

      <template #panel>
        <div class="h-[calc(100vh-5rem)] overflow-y-auto">
          <UCard
            :ui="{
              base: 'h-full',
              divide: 'divide-y-0',
              header: {
                base: postSelected ? 'h-20' : 'h-60',
                background: postSelected ? '' : 'bg-primary text-white',
                padding: 'px-4 py-5 sm:px-6',
              },
              body: {
                base: 'flex-1',
              },
            }"
          >
            <template #header>
              <div
                v-if="!postSelected"
                v-motion-fade
                class="flex flex-col justify-center space-y-4"
              >
                <div class="flex justify-end">
                  <UButton icon="i-ph-x-circle" @click="open = false" />
                </div>
                <h5 class="text-center text-3xl font-bold text-white">
                  👋
                  {{ $t("floating_button.title") }}
                </h5>
                <div>
                  <UInput
                    v-model="query"
                    :placeholder="$t('floating_button.placeholder')"
                    size="xl"
                    @input="onInput"
                  />
                </div>
              </div>
              <div v-else v-motion-fade class="flex justify-between">
                <UButton
                  icon="i-ph-arrow-left"
                  color="primary"
                  size="md"
                  square
                  @click="postSelected = null"
                />
                <div class="flex items-center justify-between gap-2">
                  <UButton
                    icon="i-ph-x-circle"
                    size="md"
                    @click="open = false"
                  />
                </div>
              </div>
            </template>
            <div v-if="postSelected" v-motion-fade>
              <h5 class="text-center text-3xl font-bold">
                {{ postSelected.title }}
              </h5>
              <EditorBlockEditor
                v-model="postSelected.content"
                :images-enabled="true"
                :editable="false"
              />
              <UDivider label="¿No resolvió tu problema?" class="mt-12 mb-4" />
              <HelpCenterWhatsappCard />
              <HelpCenterEmailCard class="mt-4" />
            </div>
            <div v-else v-motion-fade class="space-y-8 p-4 min-h-full">
              <div v-if="query">
                <LoadingProgress
                  v-if="inProgress || typing"
                  :message="$t('floating_button.searching')"
                />
                <div v-else-if="searchPosts.length > 0">
                  <span class="font-bold"
                    >{{ $t("floating_button.results") }}:</span
                  >
                  <HelpCenterVerticalNavigation
                    :posts="searchPosts"
                    @post-selected="onPostSelected"
                  />
                </div>

                <div v-else>
                  <p class="italic text-gray-600 text-center">
                    {{ $t("floating_button.no_results") }}
                  </p>
                  <UDivider
                    :label="$t('floating_button.divider.label')"
                    class="mt-12 mb-4"
                  />
                  <HelpCenterWhatsappCard />
                  <HelpCenterEmailCard class="mt-4" />
                </div>
              </div>
              <UCard
                v-for="category in helpCenterCategories"
                v-else
                :key="category.id"
              >
                <template #header>
                  <div class="flex items-center gap-2">
                    <Icon :name="category.icon" class="text-4xl text-primary" />
                    <div class="flex flex-col">
                      <span class="font-bold">{{ category.name }}</span>
                      <span class="text-sm text-gray-500">{{
                        category.description
                      }}</span>
                    </div>
                  </div>
                </template>

                <div>
                  <HelpCenterVerticalNavigation
                    :posts="category.posts"
                    @post-selected="onPostSelected"
                  />
                </div>
              </UCard>
            </div>
          </UCard>
        </div>
      </template>
    </UPopover>
  </div>
</template>

<style></style>
