<script lang="ts" setup>
const props = defineProps({
  posts: {
    type: Array as PropType<HelpCenter[]>,
    required: true,
  },
});

const emit = defineEmits(["postSelected"]);
const links = computed(() => {
  return props.posts.map((post) => {
    return {
      label: post.title ?? "Sin título",
      click: () => emit("postSelected", post),
      badge: post.for_admin ? "Admin" : undefined,
    };
  });
});
</script>

<template>
  <UVerticalNavigation :links="links" />
</template>

<style></style>
