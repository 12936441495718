import { type Editor, type Range } from "@tiptap/vue-3";

export type SlashItem = {
  title: string;
  type: "option" | "category";
  icon?: string;
  isActive?: () => boolean;
  command?: ({ editor, range }: { editor: Editor; range: Range }) => void;
  is_media?: boolean;
};

export const SLASH_ITEMS: SlashItem[] = [
  {
    title: "Textos",
    type: "category",
  },
  {
    title: "Encabezado 1",
    type: "option",
    icon: "i-ph-text-h-one-bold",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 1 })
        .run();
    },
  },
  {
    title: "Encabezado 2",
    type: "option",
    icon: "i-ph-text-h-two-bold",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 2 })
        .run();
    },
  },
  {
    title: "Encabezado 3",
    type: "option",
    icon: "i-ph-text-h-three-bold",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 3 })
        .run();
    },
  },
  {
    title: "Encabezado 4",
    type: "option",
    icon: "i-ph-text-h-four-bold",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 4 })
        .run();
    },
  },
  {
    title: "Encabezado 5",
    type: "option",
    icon: "i-ph-text-h-five-bold",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 5 })
        .run();
    },
  },
  {
    title: "Listas",
    type: "category",
  },
  {
    title: "Lista Numerada",
    icon: "i-ph-list-numbers-bold",
    type: "option",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run();
    },
  },
  {
    title: "Lista de Puntos",
    icon: "i-ph-list-bullets-bold",
    type: "option",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run();
    },
  },
  {
    title: "Lista de Puntos",
    icon: "i-ph-list-bullets-bold",
    type: "option",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run();
    },
  },
  {
    title: "Lista de Tareas",
    icon: "i-ph-list-checks-bold",
    type: "option",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor.chain().focus().deleteRange(range).toggleTaskList().run();
    },
  },
  {
    title: "Media",
    type: "category",
    is_media: true,
  },
  {
    title: "Imagen",
    icon: "i-ph-image-bold",
    type: "option",
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor.chain().focus().deleteRange(range).setImageUpload().run();
    },
    is_media: true,
  },
];

export default SLASH_ITEMS;
