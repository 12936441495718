<script lang="ts" setup></script>

<template>
  <ULink
    to="mailto:support@parafrasist.com"
    class="w-full inline-block"
    target="_blank"
  >
    <UCard>
      <template #header>
        <div class="flex items-center justify-center gap-2">
          <Icon name="i-ph-envelope" class="text-4xl text-gray-500" />
          <div class="flex flex-col text-center">
            <span class="font-bold">{{ $t("email_card.title") }}</span>
            <span class="text-sm text-gray-500">
              {{ $t("email_card.description") }}
            </span>
          </div>
        </div>
      </template>
    </UCard>
  </ULink>
</template>

<style></style>
