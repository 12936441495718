<script lang="ts" setup>
import { useEditor, EditorContent } from "@tiptap/vue-3";

import DOMPurify from "isomorphic-dompurify";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  editable: {
    type: Boolean,
    default: true,
  },
  imagesEnabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "editorCreated"]);

const extensions = useTiptapExtensions(props.imagesEnabled);

const editor = useEditor({
  autofocus: true,
  content: DOMPurify.sanitize(props.modelValue),
  editable: props.editable,
  extensions,
  onUpdate: () => {
    if (editor.value) {
      // HTML
      emit("update:modelValue", editor.value!.getHTML());

      // emit("update:modelValue", editor.value!.getJSON());
    }
  },
  onCreate: () => {
    emit("editorCreated", editor.value);
  },
});

watch(
  () => props.editable,
  (value) => {
    editor.value?.setEditable(value, false);
  },
);

watch(
  () => props.modelValue,
  (value) => {
    const isSame = (editor.value?.getHTML() as string) === (value as string);
    // JSON
    // const isSame =
    //   JSON.stringify(editor.value?.getJSON()) === JSON.stringify(value);

    if (isSame) {
      return;
    }
    editor.value?.commands.setContent(value, false);
  },
);

onBeforeUnmount(() => {
  editor.value?.destroy();
});
</script>

<template>
  <div class="w-full">
    <editor-content v-if="editor" :editor="editor" class="w-full h-full" />
    <EditorMenusBubbleMenu v-if="editor" :editor="editor" />

    <EditorMenusImageBlockMenu
      v-if="editor && imagesEnabled"
      :editor="editor"
    />
  </div>
</template>

<style scoped>
:deep() .ProseMirror {
  height: 100%;
}

:deep() .ProseMirror:focus {
  outline: none;
}

:deep() .ProseMirror p,
:deep() .ProseMirror h1 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

:deep() .ProseMirror p:first-child,
:deep() .ProseMirror h1:first-child {
  margin-top: 0px;
}

:deep() .ProseMirror p:last-child,
:deep() .ProseMirror h1:last-child {
  margin-bottom: 0px;
}

:deep() .tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
:deep() .ProseMirror ul,
:deep() .ProseMirror ol {
  padding: 0 1rem;
}
:deep() .ProseMirror ul li {
  list-style-type: disc;
}

:deep() .ProseMirror ol li {
  list-style-type: decimal;
}

:deep() ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}
:deep() ul[data-type="taskList"] p {
  margin: 0;
}
:deep() ul[data-type="taskList"] li {
  display: flex;
}
:deep() ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}
:deep() ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
}
:deep() ul[data-type="taskList"] li ul li,
:deep() ul[data-type="taskList"] li ol li {
  display: list-item;
}
:deep() ul[data-type="taskList"] li ul[data-type="taskList"] > li {
  display: flex;
}

:deep() .ProseMirror a {
  color: #7d32f5 !important;
  text-decoration: underline;
  cursor: pointer;
}

:deep() .ProseMirror p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
:deep() .ProseMirror img {
  border-radius: 14px;
}
:deep() .ProseMirror .node-imageBlock.has-focus img {
  border: 2px solid #a686ff;
}
</style>
