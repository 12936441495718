import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "about-you": () => import("/vercel/path0/middleware/about-you.ts"),
  admin: () => import("/vercel/path0/middleware/admin.ts"),
  auth: () => import("/vercel/path0/middleware/auth.ts"),
  guest: () => import("/vercel/path0/middleware/guest.ts"),
  subscribed: () => import("/vercel/path0/middleware/subscribed.ts"),
  "unabout-you": () => import("/vercel/path0/middleware/unabout-you.ts"),
  unsubscribed: () => import("/vercel/path0/middleware/unsubscribed.ts"),
  unverified: () => import("/vercel/path0/middleware/unverified.ts"),
  verified: () => import("/vercel/path0/middleware/verified.ts"),
  "sanctum:auth": () => import("/vercel/path0/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/vercel/path0/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}