<script lang="ts" setup>
import { Editor } from "@tiptap/vue-3";
import { z } from "zod";
import type { FormSubmitEvent } from "#ui/types";

const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
});

const { href: link, target } = props.editor.getAttributes("link");

const state = reactive({
  url: link || "",
  openInNewTab: target === "_blank",
});

const schema = z.object({
  url: z.string().url(),
  openInNewTab: z.boolean(),
});
type Schema = z.output<typeof schema>;

const onSubmit = (_event: FormSubmitEvent<Schema>) => {
  props.editor
    .chain()
    .focus()
    .extendMarkRange("link")
    .setLink({ href: state.url, target: state.openInNewTab ? "_blank" : "" })
    .run();
};

const unsetLink = () => {
  props.editor.chain().focus().unsetLink().run();
};

const isValidUrl = computed(() => /^(\S+):(\/\/)?\S+$/.test(state.url));
</script>

<template>
  <UForm :state="state" :schema="schema" @submit="onSubmit">
    <UFormGroup name="url">
      <div class="flex justify-between items-center gap-2">
        <UInput
          v-model="state.url"
          icon="i-ph-link-bold"
          placeholder="Introduce la URL"
          class="flex-1"
        />
        <UButton
          type="submit"
          label="Insertar"
          size="sm"
          :disabled="!isValidUrl"
        />
      </div>
    </UFormGroup>
    <UFormGroup name="openInNewTab">
      <div class="flex items-center gap-2 mt-2">
        <span class="text-white">Abrir en nueva pestaña</span>
        <UToggle v-model="state.openInNewTab" color="primary" />
      </div>
    </UFormGroup>
    <UFormGroup v-if="editor.isActive('link')">
      <div class="space-y-2 mt-2">
        <UDivider
          :ui="{
            border: {
              base: 'border-chicago-400',
            },
          }"
        />
        <UButton
          color="white"
          icon="i-ph-trash"
          block
          size="sm"
          @click="unsetLink()"
        >
          Eliminar enlace
        </UButton>
      </div>
    </UFormGroup>
  </UForm>
</template>

<style></style>
