<script lang="ts" setup>
import type { NuxtError } from "#app";

const { t } = useI18n();

useSeoMeta({
  title: t("error_page.title"),
  description: t("error_page.description"),
});

defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <UPage id="error-bg" class="h-screen bg-white">
    <UContainer class="py-16">
      <div class="p-12 text-center items-center flex flex-col gap-8">
        <TheLogo class="h-8 mb-12" />
        <span class="text-5xl">😔</span>
        <h1 class="text-5xl font-bold">
          {{ error?.message }}
        </h1>
        <div>
          <UButton @click="handleError">
            {{ $t("error_page.button") }}
          </UButton>
        </div>
      </div>
    </UContainer>
  </UPage>
</template>

<style scoped>
#error-bg {
  background: url("/public/images/error.png") center center;
}
</style>
