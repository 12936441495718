export const useProducts = () => {
  const sanctumClient = useSanctumClient();
  const user = useSanctumUser<User>();
  const { isAuthenticated } = useSanctumAuth();
  const localePath = useLocalePath();
  const products = useNuxtData<Product[]>("products").data;
  const fetchUserProducts = () =>
    useAsyncData<Product[]>("products", () =>
      sanctumClient("/user/products", {
        onResponseError: async ({ response }) => {
          const status = response.status;
          if ([401, 419, 403].includes(status)) {
            // Or
            user.value = null;
            await navigateTo(localePath("login"));
          }
        },
      }),
    );

  const fetchUnauthenticatedProducts = () =>
    useAsyncData<Product[]>("products", () => sanctumClient("/products", {}));

  const fetchProducts = () => {
    if (isAuthenticated.value) {
      return fetchUserProducts();
    } else {
      return fetchUnauthenticatedProducts();
    }
  };

  return {
    fetchUserProducts,
    fetchProducts,
    products,
  };
};
