<script lang="ts" setup>
import { Editor } from "@tiptap/core";

const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
});
const { t } = useI18n();

const items = [
  [
    {
      label: t("node_type_picker.texts"),
      slot: "category",
      disabled: true,
    },
    {
      icon: "i-ph-text-aa-bold",
      label: t("node_type_picker.texts.text"),
      isActive: () =>
        props.editor.isActive("paragraph") &&
        !props.editor.isActive("orderedList") &&
        !props.editor.isActive("bulletList") &&
        !props.editor.isActive("taskList"),
      click: () =>
        props.editor
          .chain()
          .focus()
          .lift("taskItem")
          .liftListItem("listItem")
          .setParagraph()
          .run(),
    },
    {
      label: t("node_type_picker.texts.h1"),
      icon: "i-ph-text-h-one-bold",
      click: () =>
        props.editor
          .chain()
          .focus()
          .lift("taskItem")
          .liftListItem("listItem")
          .setHeading({ level: 1 })
          .run(),
      isActive: () => props.editor.isActive("heading", { level: 1 }),
    },
    {
      label: t("node_type_picker.texts.h2"),
      icon: "i-ph-text-h-two-bold",
      click: () =>
        props.editor
          .chain()
          .focus()
          .lift("taskItem")
          .liftListItem("listItem")
          .setHeading({ level: 2 })
          .run(),
      isActive: () => props.editor.isActive("heading", { level: 2 }),
    },
    {
      label: t("node_type_picker.texts.h3"),
      icon: "i-ph-text-h-three-bold",
      click: () =>
        props.editor
          .chain()
          .focus()
          .lift("taskItem")
          .liftListItem("listItem")
          .setHeading({ level: 3 })
          .run(),
      isActive: () => props.editor.isActive("heading", { level: 3 }),
    },
    {
      label: t("node_type_picker.texts.h4"),
      icon: "i-ph-text-h-four-bold",
      click: () =>
        props.editor
          .chain()
          .focus()
          .lift("taskItem")
          .liftListItem("listItem")
          .setHeading({ level: 4 })
          .run(),
      isActive: () => props.editor.isActive("heading", { level: 4 }),
    },
    {
      label: t("node_type_picker.texts.h5"),
      icon: "i-ph-text-h-five-bold",
      click: () =>
        props.editor
          .chain()
          .focus()
          .lift("taskItem")
          .liftListItem("listItem")
          .setHeading({ level: 5 })
          .run(),
      isActive: () => props.editor.isActive("heading", { level: 5 }),
    },
    {
      label: t("node_type_picker.lists"),
      slot: "category",
      disabled: true,
    },
    {
      label: t("node_type_picker.lists.ol"),
      icon: "i-ph-list-numbers-bold",
      click: () => props.editor.chain().focus().toggleOrderedList().run(),
      isActive: () => props.editor.isActive("orderedList"),
    },
    {
      label: t("node_type_picker.lists.ul"),
      icon: "i-ph-list-bullets-bold",
      click: () => props.editor.chain().focus().toggleBulletList().run(),
      isActive: () => props.editor.isActive("bulletList"),
    },
    {
      label: t("node_type_picker.lists.tl"),
      icon: "i-ph-list-checks-bold",
      click: () => props.editor.chain().focus().toggleTaskList().run(),
      isActive: () => props.editor.isActive("taskList"),
    },
  ],
];

const activeNode = computed(() => {
  return items
    .flat()
    .find((item) => item.isActive?.() && item.slot !== "category");
});
</script>

<template>
  <div>
    <UDropdown
      :items="items"
      :ui="{
        item: {
          active: 'bg-chicago-600 dark:bg-gray-900 text-white dark:text-white',
          inactive: 'text-white dark:text-gray-200',
          disabled: 'cursor-text select-text',
          icon: {
            base: 'flex-shrink-0 w-5 h-5',
            active: 'text-white dark:text-gray-400',
            inactive: 'text-white dark:text-gray-500',
          },
        },
        background: 'bg-chicago-500 dark:bg-gray-800',
        ring: 'ring-1 ring-chicago-500 dark:ring-chicago-700',
      }"
    >
      <UTooltip
        :ui="{
          background: 'bg-chicago-500',
          color: 'text-white',
        }"
        :text="$t('node_type_picker.change_type')"
        color="chicago"
      >
        <EditorMenusButtonMenu
          v-if="activeNode"
          color="chicago"
          :label="activeNode.label"
          trailing-icon="i-heroicons-chevron-down-20-solid"
        />
      </UTooltip>
      <template #category="{ item }">
        <p class="text-sm text-white px-1">{{ item.label }}</p>
      </template>
      <template #item="{ item }">
        <div class="flex justify-between items-center w-full">
          <div class="flex items-center gap-2">
            <UIcon :name="item.icon" />
            <span>
              {{ item.label }}
            </span>
          </div>

          <UIcon v-if="item.isActive()" name="i-ph-check-bold" />
        </div>
      </template>
    </UDropdown>
  </div>
</template>

<style></style>
