<template>
  <div
    ref="optionsMenu"
    class="options-menu flex flex-col gap-1 bg-chicago-500 p-1 rounded-lg max-h-60 overflow-y-auto"
  >
    <template v-for="(item, index) in items" :key="index">
      <div class="p-1">
        <EditorMenusButtonMenu
          v-if="item.type === 'option'"
          :key="index"
          color="chicago"
          :icon="item.icon"
          :label="item.title"
          :class="{
            'ring-chicago-600 ring-2 bg-chicago-600': index === selectedIndex,
          }"
          class="w-full"
          @click="selectItem(index)"
        />
        <p v-else class="text-sm text-white px-1">{{ item.title }}</p>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { Editor } from "@tiptap/core";

type SlashItem = {
  title: string;
  type: "option" | "category";
  icon?: string;
  isActive?: () => boolean;
  command?: ({ editor, range }: { editor: Editor; range: Range }) => void;
};

const props = defineProps({
  items: {
    type: Array as PropType<SlashItem[]>,
    required: true,
  },
  command: {
    type: Function,
    required: true,
  },
});

const optionsMenu = ref<HTMLElement | null>(null);
const selectedIndex = ref(0);

watch(props.items, () => {
  selectedIndex.value = 0;
});

const onKeyDown = ({ event }: { event: KeyboardEvent }) => {
  if (event.key === "ArrowUp") {
    upHandler();
    return true;
  }

  if (event.key === "ArrowDown") {
    downHandler();
    return true;
  }

  if (event.key === "Enter") {
    enterHandler();
    return true;
  }

  return false;
};

defineExpose({
  onKeyDown,
});

const upHandler = () => {
  if (
    props.items[
      (selectedIndex.value + props.items.length - 1) % props.items.length
    ].type === "category"
  ) {
    // Skip category items
    selectedIndex.value =
      (selectedIndex.value + props.items.length - 2) % props.items.length;
    fixScroll();

    return;
  }

  selectedIndex.value =
    (selectedIndex.value + props.items.length - 1) % props.items.length;

  fixScroll();
};

const fixScroll = () => {
  const liH = optionsMenu.value?.children[selectedIndex.value].clientHeight;

  // If is the last item, scroll to the bottom
  if (selectedIndex.value === props.items.length - 1) {
    optionsMenu.value!.scrollTop = optionsMenu.value!.scrollHeight;
    return;
  }

  // Only scroll if the item is not visible
  if (
    optionsMenu.value!.scrollTop > liH! * selectedIndex.value ||
    optionsMenu.value!.scrollTop + optionsMenu.value!.clientHeight <
      liH! * (selectedIndex.value + 1)
  )
    if (selectedIndex.value === 1) optionsMenu.value!.scrollTop = 0;
    else optionsMenu.value!.scrollTop = liH! * selectedIndex.value;
};

const downHandler = () => {
  if (
    props.items[(selectedIndex.value + 1) % props.items.length].type ===
    "category"
  ) {
    // Skip category items
    selectedIndex.value = (selectedIndex.value + 2) % props.items.length;
    fixScroll();

    return;
  }

  selectedIndex.value = (selectedIndex.value + 1) % props.items.length;

  fixScroll();
};

const enterHandler = () => {
  selectItem(selectedIndex.value);
};

const selectItem = (index: number) => {
  const item = props.items[index];

  if (item) {
    props.command(item);
  }
};
</script>

<style>
.options-menu::-webkit-scrollbar {
  width: 6px;
}

.options-menu::-webkit-scrollbar-track {
  border-radius: 10px;
}

.options-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #353535;
}
</style>
