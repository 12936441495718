<script lang="ts" setup>
const props = defineProps({
  colorMode: {
    type: String,
    default: "light",
  },
});

const isDark = computed(() => props.colorMode === "dark");
</script>

<template>
  <NuxtImg
    :src="
      isDark
        ? '/images/parafrasist-logo-dark.svg'
        : '/images/parafrasist-logo.svg'
    "
    alt="Parafrasist Logo"
  />
</template>
