<script lang="ts" setup>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
const props = defineProps(nodeViewProps);

const onUpload = (url: string) => {
  props.editor
    .chain()
    .setImageBlock({ src: url })
    .deleteRange({ from: props.getPos(), to: props.getPos() })
    .focus()
    .run();
};
</script>

<template>
  <NodeViewWrapper>
    <EditorImageUploader :on-upload="onUpload" />
  </NodeViewWrapper>
</template>

<style></style>
